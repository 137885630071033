import { PropsWithChildren, ReactElement, useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { Stripe, loadStripe } from '@stripe/stripe-js';

import { logger } from '@breathelife/monitoring-frontend';
import { Language } from '@breathelife/types';
import { StripeContext } from './StripeContext';
import { StripeBLErrorCode, StripeConfig, stripeConfigSchema } from './types/index';

export type StripeProviderProps = PropsWithChildren<{
  config: StripeConfig;
  language?: keyof typeof Language | Language;
}>;

export function StripeProvider(props: StripeProviderProps): ReactElement {
  const { children, config, language = Language.en } = props;
  const [stripe, setStripe] = useState<Stripe | null>();

  useEffect(() => {
    const parsedConfig = stripeConfigSchema.safeParse(config);

    if (!parsedConfig.success) {
      logger.error({
        message: 'Failed to initialize Stripe: Config schema validation failed',
        BLCode: StripeBLErrorCode.SdkConfigValidationFailure,
      });
      return;
    }

    let isCancelled = false;

    const loadSdk = async (config: StripeConfig): Promise<void> => {
      const { connectedAccountId: stripeAccount, publicKey } = config;

      try {
        const stripe = await loadStripe(publicKey, { stripeAccount });

        if (!stripe) {
          // 'null' is a valid return from 'loadStripe', but an alert should still be triggered
          logger.error({
            message: 'Failed to initialize Stripe: loadStripe failed to return as expected',
            BLCode: StripeBLErrorCode.SdkLoadFailure,
          });
        }

        if (!isCancelled) setStripe(stripe);
      } catch (err: any) {
        logger.error({
          message: 'Failed to initialize Stripe',
          details: err,
          BLCode: StripeBLErrorCode.SdkLoadFailure,
        });
        return;
      }
    };

    void loadSdk(parsedConfig.data);

    return () => {
      isCancelled = true;
    };
    // This will run one time, we won't handle situations where
    //  Stripe credentials change at runtime
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StripeContext.Provider value={{ language, stripe }}>
      <Elements stripe={stripe ?? null}>{children}</Elements>
    </StripeContext.Provider>
  );
}
