import { createContext, useContext } from 'react';
import { Stripe } from '@stripe/stripe-js';

import { Language } from '@breathelife/types';

export type StripeContextType = {
  language: keyof typeof Language | Language;
  stripe?: Stripe | null;
};

export const StripeContext = createContext<StripeContextType>({} as StripeContextType);

export function useStripeContext(): StripeContextType {
  return useContext<StripeContextType>(StripeContext);
}
