import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';
import styled, { css } from 'styled-components';

import { TextField } from '@breathelife/mui';

const ErrorStyle = css`
  &&,
  &&:hover {
    border-color: ${({ theme }) => theme.colors.error};
  }
`;

const FieldCommonStyle = css`
  border: 1px solid ${({ theme }) => theme.colors.grey[50]};
  border-radius: 4px;
  padding: 16px 12px;
  &:hover {
    border-color: ${({ theme }) => theme.colors.grey[60]};
  }
`;

const FocusedStyle = css`
  border-color: ${({ theme }) => theme.colors.grey[60]};
`;

export const CardExpiryInput = styled(CardExpiryElement)<{ $error?: boolean }>`
  ${FieldCommonStyle};
  ${(props) => (props.$error ? ErrorStyle : undefined)}
  &.StripeElement--invalid {
    ${ErrorStyle}
  }
  &.StripeElement--focus {
    ${FocusedStyle}
    ${(props) => (props.$error ? ErrorStyle : undefined)}
  }
`;

export const CardCvcInput = styled(CardCvcElement)<{ $error?: boolean }>`
  ${FieldCommonStyle};
  ${(props) => (props.$error ? ErrorStyle : undefined)}
  &.StripeElement--invalid {
    ${ErrorStyle}
  }
  &.StripeElement--focus {
    ${FocusedStyle}
    ${(props) => (props.$error ? ErrorStyle : undefined)}
  }
`;

export const CardNumberInput = styled(CardNumberElement)<{ $error?: boolean }>`
  ${FieldCommonStyle};
  ${(props) => (props.$error ? ErrorStyle : undefined)}
  input {
    caret-color: ${({ theme }) => theme.colors.primary[40]};
  }
  &.StripeElement--invalid {
    ${ErrorStyle}
  }
  &.StripeElement--focus {
    ${FocusedStyle}
    ${(props) => (props.$error ? ErrorStyle : undefined)}
  }
`;

export const TextInput = styled(TextField)`
  ${FieldCommonStyle};
  &&&&& {
    input {
      padding: 16px 12px;
    }
    .Mui-error {
      ${ErrorStyle}
    }
    .Mui-focused {
      input {
        caret-color: ${({ theme }) => theme.colors.primary[40]};
      }
      .MuiOutlinedInput-notchedOutline {
        ${FocusedStyle};
        border-width: 1px;
      }
    }
  }
`;
