import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
} from '@stripe/stripe-js';
import { FormErrors, FormValidators } from '../../shared';

export type StripeCardChangeEvent =
  | StripeCardExpiryElementChangeEvent
  | StripeCardCvcElementChangeEvent
  | StripeCardNumberElementChangeEvent;

export type StripeCardInputValue = {
  isComplete: boolean;
  isEmpty: boolean;
};

export enum StripeFormKey {
  cardCvc = 'cardCvc',
  cardExpiry = 'cardExpiry',
  cardNumber = 'cardNumber',
  postalCode = 'postalCode',
}

export type StripeFormData = {
  [key in Extract<StripeFormKey, 'postalCode'>]: string;
} & {
  [key in Exclude<StripeFormKey, 'postalCode'>]: StripeCardInputValue;
};

export type StripeFormErrors = FormErrors<StripeFormKey>;

export type StripeFormValidators = FormValidators<StripeFormKey, StripeFormData>;
