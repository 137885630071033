import { Language } from '@breathelife/types';
import { StripeFormKey } from '../types';

export const localizedFormLabels: Record<Language, Record<StripeFormKey, string>> = {
  [Language.en]: {
    [StripeFormKey.cardCvc]: 'CVC',
    [StripeFormKey.cardExpiry]: 'Expiry',
    [StripeFormKey.cardNumber]: 'Card Number',
    [StripeFormKey.postalCode]: 'Postal Code',
  },
  [Language.fr]: {
    [StripeFormKey.cardCvc]: 'CVC',
    [StripeFormKey.cardExpiry]: "Date d'expiration",
    [StripeFormKey.cardNumber]: 'Numéro de carte',
    [StripeFormKey.postalCode]: 'Code Postal',
  },
};

export const localizedFormErrors: Record<Language, Record<StripeFormKey, { required: string; invalid: string }>> = {
  [Language.en]: {
    [StripeFormKey.cardCvc]: {
      invalid: 'Invalid CVC number',
      required: 'Missing CVC number',
    },
    [StripeFormKey.cardExpiry]: {
      invalid: 'Invalid expiry date',
      required: 'Missing expiry date',
    },
    [StripeFormKey.cardNumber]: {
      invalid: 'Invalid card number',
      required: 'Missing card number',
    },
    [StripeFormKey.postalCode]: {
      invalid: 'Invalid postal code',
      required: 'Missing postal code',
    },
  },
  // TODO: Add French translations in https://breathelife.atlassian.net/browse/HOT-2391
  // Currently, the Stripe inputs with errors will display an error UI, but will not
  // display any error messages – HOT-2391 aims to resolve this
  [Language.fr]: {
    [StripeFormKey.cardCvc]: {
      invalid: 'Invalid CVC number',
      required: 'Missing CVC number',
    },
    [StripeFormKey.cardExpiry]: {
      invalid: 'Invalid expiry date',
      required: 'Missing expiry date',
    },
    [StripeFormKey.cardNumber]: {
      invalid: 'Invalid card number',
      required: 'Missing card number',
    },
    [StripeFormKey.postalCode]: {
      invalid: 'Invalid postal code',
      required: 'Missing postal code',
    },
  },
};
